import $ from "jquery";

const passwordInputClear = () => {
  const EVENT_IDENTIFIER = "passwordInputClear";
  const UNCHANGED_PASSWORD_CLASS = "password--unchanged";
  const PASSWORD_INPUT_SELECTOR = `input[type=password].${UNCHANGED_PASSWORD_CLASS}`;

  $('input[type=password]').addClass(UNCHANGED_PASSWORD_CLASS);

  $(document).on(`focus.${EVENT_IDENTIFIER} keydown.${EVENT_IDENTIFIER} keyup.${EVENT_IDENTIFIER} click.${EVENT_IDENTIFIER}`, PASSWORD_INPUT_SELECTOR, (event) => {
    event.target.setSelectionRange(0, event.target.value.length);
  });

  $(document).on(`input.${EVENT_IDENTIFIER}`, PASSWORD_INPUT_SELECTOR, (event) => {
    $(event.target).removeClass(UNCHANGED_PASSWORD_CLASS);
    $(`#${event.target.id}-has-changed`).val("true");
    $("#"+$.escapeSelector(event.target.id)+"-has-changed").val("true");
  });
};

export default passwordInputClear;
