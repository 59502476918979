import $ from "jquery";

const csrfInitialization = () => {
  /**
   * Parse page root URL, for example "https://example.com" from "https://example.com/some/path"
   * @returns {string} page root URL string
   */
  const parsePageRootUrl = () => {
    const currentUrl = window.location.toString();
    return currentUrl.substring(0, currentUrl.indexOf("/", currentUrl.indexOf("//") + 2));
  };

  /**
   * Determine if provided form has already CSRF token hidden field appended or not
   * @param form
   * @param parameterName
   * @returns {boolean} true if token field is appended, false if not
   */
  const isCsrfTokenIncluded = (form, parameterName) => form.find(`input[name="${parameterName}"]`).length > 0;

  /**
   * Determine if provided form points to a local server, i.e., if we are not exposing CSRF tokens by sending them to remote URL not hosted by our
   * application. The valid value of form action is absolute URL starting with current page domain, relative URI and undefined action value (because
   * these are serialized by local AJAX functions)
   * @param form
   * @param currentPageDomain
   * @returns {boolean} true if form points to local server, false if not
   */
  const isFormPointingToLocalServerUri = (form, currentPageDomain) => {
    const action = form.attr("action");
    return typeof action === "undefined" || action.indexOf("/") === 0 || action.indexOf(currentPageDomain) === 0;
  };

  const isFormMethodGET = (form) => {
    const method = form.attr("method");
    if(method == null) {
      return true;
    }
    return method.toUpperCase() === "GET";
  }

  const token = $("meta[name='_csrf']").attr("content");
  const headerName = $("meta[name='_csrf_header']").attr("content");
  const parameterName = $("meta[name='_csrf_parameter']").attr("content");
  const tokenHiddenField = `<input type="hidden" name="${parameterName}" value="${token}" />`;
  const currentPageDomain = parsePageRootUrl();

  /**
   * Setup and initialize CSRF token appender functions.
   */
  const initializeCsrfTokenAppenderMethods = () => {

    // enhance each AJAX request with CSRF token header before it is send
    $(document).ajaxSend((e, xhr) => xhr.setRequestHeader(headerName, token));

    // append hidden field with CSRF token to every form after the page content is loaded
    $(() => {
      $("form").each((index, element) => {
        const form = $(element);
        if (isFormPointingToLocalServerUri(form, currentPageDomain) && !isFormMethodGET(form)) {
          form.append(tokenHiddenField);
        }
      });
    });

    // append hidden field with CSRF token to every new form right before it is send
    $(document).on("submit", "form", (event) => {
      const form = $(event.target);
      if (isFormPointingToLocalServerUri(form, currentPageDomain) && !isCsrfTokenIncluded(form, parameterName) && !isFormMethodGET(form)) {
        form.append(tokenHiddenField);
      }
    });

  };

  // do not initialize anything in case when token is empty, i.e., CSRF protection is disabled
  if (token.length > 0) {
    initializeCsrfTokenAppenderMethods();
  }
};

export default csrfInitialization;
